import { render, staticRenderFns } from "./RoomSelected.vue?vue&type=template&id=b0f2c0c8&scoped=true"
import script from "./RoomSelected.vue?vue&type=script&lang=js"
export * from "./RoomSelected.vue?vue&type=script&lang=js"
import style0 from "./RoomSelected.vue?vue&type=style&index=0&id=b0f2c0c8&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b0f2c0c8",
  null
  
)

export default component.exports
import {QIcon,QImg} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QIcon,QImg})
