
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import LightBox from "vue-image-lightbox";
import "swiper/css/swiper.css";
export default {
  props: ["detail", "img", "amenities"],
  components: {
    Swiper,
    SwiperSlide,
    LightBox,
  },
  data() {
    return {
      fullImg: false,
      media: [],
      lang: this.$router.currentRoute.params.lang,
      swiperOption: {
        effect: "coverflow",
        grabCursor: true,
        centeredSlides: true,
        slidesPerView: "auto",
        loop: true,
        autoplay: {
          delay: 5000,
          disableOnInteraction: false,
        },
        coverflowEffect: {
          rotate: 50,
          stretch: 0,
          depth: 100,
          modifier: 1,
          slideShadows: true,
        },
        pagination: {
          el: ".swiper-pagination",
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
    };
  },
  methods: {
    handleClickSlide(index, reallyIndex) {
      this.$refs.lightbox.showImage(reallyIndex);
    },
    GetDataDetail() {
      this.img.forEach((arr) => {
        this.media.push({
          thumb: arr.img.url,
          src: arr.img.url,
          caption: "caption to display. receive <html> <b>tag</b>",
        });
      });
    },
  },
  mounted() {
    this.GetDataDetail();
  },
  watch: {
    $route() {
      this.lang = this.$router.currentRoute.params.lang;
    },
  },
};
